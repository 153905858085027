.trade-correction-view {
  width: 100%;
  .trade-correction-overlay {
    background: transparent;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
  }

  .trade-correction-item {
    border-bottom: 1px solid #e0e0e0;
    &.disabled {
      border-bottom: 0px;
    }
  }
  .date-select .select-input {
    min-width: unset !important;
  }
  .trade-detail-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .trade-details {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4f4f4f;
    margin: 15px 0;
  }
  .additional-note {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin: 0;
    &.disabled {
      background: #f2f2f2 !important;
    }
    label {
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;
      color: #4f4f4f;
    }
    textarea {
      border: unset;
      padding: 0 !important;
      resize: unset;
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        background-color: #f2f2f2;
      }
    }
  }

  .select__control {
    border: 1px solid #e0e0e0;
    .select__placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #4f4f4f !important;
    }
    svg {
      color: #4f4f4f;
    }
  }
  .select-input .select__control--menu-is-open {
    margin-bottom: 0 !important;
  }
  .attach-note {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #333333;
    padding-bottom: 20px;
    position: relative;
    &.disabled {
      padding-bottom: 0px;
    }
    span {
      cursor: pointer;
    }
  }
}
