.section-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .bullet-danger {
    background-color: #eb5757;
    margin-left: 4px;
  }
  button {
    width: fit-content;
    padding: 0 18px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    min-height: 42px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #5e5873;
    margin-right: 20px;
    text-transform: capitalize;
    margin-bottom: 30px;
    border-radius: 4px;
    @media screen and (max-width: 540px) {
      width: 40%;
      font-size: 12px;
    }
    &.active {
      background: #2F80ED;
      color: #fff;
    }
    &:focus {
      outline: none;
    }
  }
}
