// =========================================================================================
//   File Name: timeline.scss
//   Description: Timeline style.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: Timeline
// ========================================================================

.timeline {
	padding: 0;
	margin-bottom: 0;
	margin-left: 1rem;
	list-style: none;

	// Timeline Item
	.timeline-item {
		position: relative;
		padding-left: 2.5rem;
		border-left: 1px solid #ebe9f1;
		border-left: 2px dashed #ebe9f1;
		&:not(:last-child) {
			padding-bottom: 1.8rem;
		}

		// Timeline Point
		.timeline-point {
			position: absolute;
			left: -0.85rem;
			top: 0;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 12px;
			width: 12px;
			text-align: center;
			border-radius: 50%;
			border: 1px solid #7367f0;
			background-color: white;

			// Timeline Point Indicator
			&.timeline-point-indicator {
				left: -0.412rem;
				top: 0.07rem;
				height: 12px;
				width: 12px;
				border: 0;
				background-color: #7367f0;

				&:before {
					content: '';
					background: rgba(115, 103, 240, 0.12);
					height: 20px;
					width: 20px;
					display: block;
					position: absolute;
					top: -0.285rem;
					left: -0.285rem;
					border-radius: 50%;
				}
			}

			// Icons
			i,
			svg {
				color: #7367f0;
				font-size: 20px;
				vertical-align: baseline;
			}
		}

		.timeline-event {
			position: relative;
			width: 100%;
			min-height: 20px;
			// background-color: transparent;

			.timeline-event-time {
				font-size: 20px;
				color: #b9b9c3;
			}
		}

		&:last-of-type {
			border-left-color: transparent !important;
			&:after {
				content: '';
				position: absolute;
				left: -1px;
				bottom: 0;
				width: 1px;
				height: 100%;
				background: linear-gradient(white, transparent);
			}
		}
	}
}

.audit-trail {
	border-bottom: 1px solid hsla(0, 0%, 56.9%, 0.3);
	padding-top: 20px;
	padding-bottom: 20px;
	.audit-trail-detail p {
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		color: #4f4f4f;
	}
	.multiple-imgs {
		width: 40%;
		position: relative;
		margin-right: 0;
		margin-left: auto;
		border-radius: 2px;
		bottom: 20px;
		overflow: hidden;
		span {
			position: absolute;
			color: white;
			font-weight: 900;
			top: 0;
			right: 0;
			bottom: 0;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
		}
	}
}

.audit-trail-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	h3 {
		font-weight: 600;
		font-size: 16px;
		color: #333333;
		margin: 0;
	}
	border-bottom: 1px solid #9191914d;
	padding-bottom: 20px;
	.primary-text-btn {
		padding: 0;
	}
}

.audit-trail-note {
	display: flex;
	flex-wrap: wrap;
	justify-content: end;
}

.audit-trail-item {
	margin-bottom: 10px;
	span {
		color: #828282;
		width: 70px;
		display: inline-block;
		text-transform: capitalize;
	}
	font-weight: 400;
	font-size: 12px;
	color: #333333;
}

.audit-trail-details {
	font-size: 14px;
	line-height: 100%;
	color: #333333;
	.submitter {
		font-weight: 500;
		margin-right: 8px;
	}
	.creater {
		font-weight: 400;
	}
}
