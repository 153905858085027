.action-detail-outer {
	.details-view {
		padding-bottom: 28px;
	}
	.section-tabs-border {
		border-top: 1px solid #e0e0e0;
		padding-top: 20px;
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 40px;
		padding-bottom: 20px;
	}

	.action-detail-list {
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 12px;
		.action-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;

			h4 {
				margin-bottom: 0;
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.advanced-btn {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				background: #4f4f4f;
				color: white;
				border: none;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 15px;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}

	.action-detail-list {
		.action-detail-title {
			color: #333;
			font-size: 28px;
			font-weight: 700;
			margin-bottom: 12px;
			width: 88%;
		}

		.action-detail-sub-title {
			color: #4f4f4f;
			font-size: 10px;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 8px;
		}

		p {
			color: #4f4f4f;
			font-size: 15px;
			font-weight: 300;
			line-height: 155%;
		}
	}

	.action-task-details {
		margin-bottom: 20px;
		border-bottom: 1px solid #e0e0e0;

		p {
			color: #828282;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			display: flex;
			margin-bottom: 10px;

			.action-task-name {
				width: 125px;
				min-width: 125px;
			}

			.action-task-value {
				color: #333;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				margin-left: 20px;

				.assignee-avatar {
					width: 15px;
					height: 15px;
					border-radius: 50%;
					margin-right: 6px;
				}
			}
		}
	}

	.action-task-border {
		border-bottom: none !important;
	}

	.card-action-btn {
		.btn {
			border-radius: 4px;
			color: #333;
			text-align: center;
			font-size: 10px;
			font-weight: 700;
			min-width: 100px;
			height: unset;

			&.btn-success {
				border: 1px solid #76e0a6;
				background: #76e0a6;
			}

			&.btn-danger {
				border: 1px solid #f68b8b;
				background: #f68b8b;
			}
		}

		.common-button {
			color: #fff;
		}
	}

	.inspection-detail-list {
		padding-left: 20px;
		width: 330px;
		padding: 0;
		.sr-no {
			color: #333;
			font-size: 15px;
			font-weight: 500;
			line-height: 150%;
			margin-right: 22px;
		}
		li {
			padding-bottom: 12px;
			font-family: 'Montserrat';
			font-weight: 400;
			line-height: 18.75px;
			font-size: 15px;
			margin-bottom: 30px;
			color: #333;
			list-style: none;
			display: flex;
			padding-bottom: 0;
		}
	}
	.activity-detail-item {
		color: #4f4f4f;
		font-size: 14px;
		font-family: Montserrat;
		font-weight: 500;
		line-height: 14px;
		margin-bottom: 12px;
		.activity-detail-item-name {
			font-weight: 600;
			width: 140px;
			margin-right: 10px;
			display: inline-block;
		}
	}
}

.setup-accordion-outer {
	margin: 20px;

	.setup-accordion {
		background-color: white;

		.setup-accordion-header {
			border-top: 1px solid #e0e0e0;

			.action-list {
				padding-top: 0;

				.message-loader {
					font-size: 14px;
					padding-bottom: 20px;
				}

				.action-card {
					border: 1px solid #e0e0e0;
					background-color: #f8f8f8;
					padding: 12px;
					.action-type-name {
						font-size: 14px;
						font-weight: 500;
						line-height: 100%;
					}
					.task-note {
						font-size: 10px;
					}

					.failed-activity-outerbox p {
						font-size: 10px;

						img {
							width: 10px;
						}
					}

					.task-date {
						color: #828282;
						font-size: 10px;
						font-style: normal;
						font-weight: 400;
						line-height: 100%;

						img {
							width: 10px;
						}
					}

					.failed-activity-outerbox .failed-activity-outerbox-img {
						height: 60px;
						width: 60px;
					}
				}
			}

			.message-card-box {
				.card {
					height: 230px;
				}

				margin-bottom: 20px;
			}

			.advanced-btn {
				justify-content: space-between;
				width: 100%;
				padding: 20px 0px;
				display: flex;
				align-items: center;
				color: #4f4f4f;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: 100%;
				text-decoration-line: underline;

				svg {
					color: #000;
					width: 18px;
				}

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
}

.custom-notes-content {
	border-bottom: 1px solid #e0e0e0;
	margin-bottom: 20px;
}

.primary-notes {
	.additional-note-title {
		font-weight: 500;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
		margin-bottom: 0px;
	}
	.additional-note-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
		svg {
			width: 18px;
			cursor: pointer;
		}
	}
	.additional-note {
		min-height: 104px;
		border-radius: 2px;
		border: 1px solid #e0e0e0;
		background: #fff;
		padding: 16px;
		width: 570px;
		margin-bottom: 28px !important;
		.title {
			font-weight: 600;
			font-size: 12px;
			line-height: 24px;
			text-decoration-line: underline;
			color: #4f4f4f;
			display: flex;
			margin-bottom: 10px;
			justify-content: space-between;

			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 24px;
				color: #4f4f4f;
			}
		}

		.note-details {
			font-weight: 400;
			font-size: 13px;
			line-height: 155%;
			color: #828282;
		}
	}
}
.react-datepicker-wrapper {
	width: 100%;
	.react-datepicker__close-icon{
		top: -8px;
	}
}

.not-ready-reschedule-date {
	.react-datepicker__input-container {
		display: none;
	}
}

.attention-description{
	font-size: 14px;
	line-height: 18.2px;
	color: #4F4F4F;
	font-weight: 300;
}
.note-detail{
	margin-bottom: 10px;
	textarea{
		min-height: 100px;
	}
}

.action-input{
	.not-ready-reschedule-date {
		.react-datepicker__input-container {
			display: none;
		}
	}

	.not-ready-action {
		.select__control {
			min-height: 25px;
			height: 25px;
			align-items: baseline;
			.select__value-container {
				height: 25px;
				padding: 0;
				padding-left: 5px;
				.select__placeholder {
					font-size: 10px;
					font-weight: 500;
					color: #333333 !important
				}
				.select__single-value {
					font-size: 10px;
					font-weight: 500;
					color: #333333 !important;
					top: 60%;
				}
			}
			.select__clear-indicator {
				display: none;
			}
			.select__indicator {
				padding: 0;
				padding-right: 5px;
			}
		}
	}
}


.grey-button {
	border: 1px solid #E0E0E0 !important;
	background-color: #E0E0E0 !important;
}

.submit-button {
	border: 1px solid #2F80ED !important;
	background-color: #2F80ED !important;
	color: #fff !important;

	&.disabled {
		opacity: 0.5 !important;
	}
}