.btn-success {
	border: 1px solid #76e0a6;
	background-color: #76e0a6;
	&:hover {
		border: 1px solid #76e0a6;
		background-color: #76e0a6;
	}
}

.btn-danger {
	border: 1px solid #f68b8b;
	background-color: #f68b8b;
	&:hover {
		border: 1px solid #f68b8b;
		background-color: #f68b8b;
	}
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
	background-color: #e0e0e0;
	border: 1px solid #e0e0e0;
}
.secondary-solid {
	background-color: #e0e0e0;
	border: 1px solid #e0e0e0;
	&:focus {
		background-color: #e0e0e0;
		border: 1px solid #e0e0e0;
	}
	color: #333;
	&:hover {
		background-color: #e0e0e0;
		border: 1px solid #e0e0e0;
	}
}

.btn {
	border-radius: 4px;
	color: #333;
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 10px;
	text-transform: capitalize;
	&:hover {
		color: #333;
	}
	&:focus {
		box-shadow: none;
	}
}

.outline-grey-button {
	border: 1px solid #e0e0e0;
	background-color: white;
	color: #4f4f4f;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	&:focus,
	&:hover {
		background-color: white !important;
		border: 1px solid #e0e0e0;
		color: #4f4f4f;
	}
}
.btn.disabled,
.btn:disabled {
	color: #333;
}

.common-button {
	padding: 10px 22px;
	background: #1b68b6 !important;
	border-radius: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	height: 40px;
	line-height: 13px;
	letter-spacing: 0.366667px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	border: 1px solid #1b68b6 !important;
	&:active,
	&:focus {
		background: #1b68b6 !important;
	}
	&:hover {
		box-shadow: 0 8px 25px -8px #1b68b6 !important;
		color: #ffffff;
	}
	&.disabled {
		background: gray !important;
		box-shadow: none !important;
	}
	svg {
		width: 17px;
		margin: 0px 5px;
	}
}

.button-control {
	padding: 10px 22px;
	border-radius: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	height: 40px;
	line-height: 13px;
	letter-spacing: 0.366667px;
	text-align: center;
	letter-spacing: 0.4px;
	color: #ffffff;
	&.no-border {
		border: none !important;
	}
	&.disabled {
		background: gray !important;
		box-shadow: none !important;
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 10px;
	}
}
.back-btn {
	width: 100px;
	margin: 10px 0;
}
