.assigned-action {
	.assigned-action-header {
		border-bottom: 1px solid #e0e0e0;
		padding-top: 15px;
		background-color: #f8f8f8;

		.action-head {
			h4 {
				color: #333;
				font-size: 28px;
				font-style: normal;
				font-weight: 700;
				line-height: 100%;
				margin-bottom: 10px;

				@media (max-width: 767px) {
					font-size: 22px;
				}
			}

			p {
				color: #4f4f4f;
				font-size: 15px;
				font-style: normal;
				font-weight: 300;
				line-height: 155%;
				padding-bottom: 5px;

				@media (max-width: 767px) {
					font-size: 14px;
					border: none;
					padding: 0;
					margin: 0;
					margin-bottom: 12px;
				}
			}
		}

		.action-details {
			span.action-details-title {
				width: 120px;
				display: inline-block;
				color: #828282;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;

				@media (max-width: 767px) {
					font-size: 12px;
					width: 90px;
				}
			}

			p {
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				margin-bottom: 0;

				@media (max-width: 767px) {
					font-size: 12px;
				}

				span {
					color: #333;
				}
			}
		}
	}
}

.action-list {
	padding-top: 20px;
	border-bottom: 1px solid #e0e0e0;
	margin-bottom: 20px;
	padding-bottom: 20px;

	h6 {
		color: #333;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
	}

	.action-card {
		border-radius: 2px;
		border: 1px solid #e0e0e0;
		background: #fff;
		margin-bottom: 20px;
		padding: 22px 20px;
		.submit-close-btn {
			.common-button {
				height: unset;
			}
		}
		.note-field {
			margin-bottom: 20px;
		}

		.has-no-options {
			padding-bottom: 2px;
		}

		.task-map {
			color: #333;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			display: flex;
			align-items: center;

			img {
				margin-right: 10px;
			}
		}

		.failed-activity-outerbox {
			display: flex;

			.failed-activity-content {
				width: 100%;
			}

			&.show-image .failed-activity-content {
				width: 76%;
			}

			.failed-activity-outerbox-img {
				width: 100px;
				border-radius: 4px;
				margin-right: 10px;
				position: relative;
				cursor: pointer;
				height: 100px;
				margin-bottom: 20px;

				img {
					border-radius: 4px;
					width: 100%;
					height: 100%;
				}

				.view-all {
					position: absolute;
					bottom: 10px;
					right: 0;
					left: 0;
					text-align: center;
					color: white;
					color: #fff;
					font-family: Montserrat;
					font-size: 7px;
					font-style: normal;
					font-weight: 600;
					line-height: 100%;
					text-transform: uppercase;
				}
			}
		}

		.action-type-name {
			color: #333;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 100%;
			width: 55%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;
			padding-bottom: 10px;
			margin-bottom: 0;

			&.expanded {
				white-space: normal;
				overflow: visible;
				text-overflow: clip;
				cursor: default;
			}
		}

		.task-note-discription {
			display: flex;
			align-items: flex-start;
		}

		.task-note {
			color: #333;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
			padding-left: 10px;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;
			width: 90%;
			display: inline-block;

			&.expanded {
				white-space: normal;
				overflow: visible;
				text-overflow: clip;
				cursor: default;
				word-break: break-all;
			}
		}

		.task-date {
			color: #828282;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			display: flex;
			align-items: center;

			img {
				margin-right: 5px;
			}
		}
	}

	.actions-head {
		text-align: right;
	}

	.action-outer-scroll {
		height: 590px;
		height: calc(87vh - 206px);
		overflow-y: scroll;
		/* width */
		&::-webkit-scrollbar {
			width: 5px;
			height: 10px;
			border-radius: 5px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: transparent;
			border-radius: 5px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 5px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}

	.select__indicator-separator {
		display: none;
	}

	.select__placeholder {
		color: rgb(185, 185, 195);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	}

	.filter-head {
		display: flex;
		justify-content: space-between;
		&.filter-applied {
			span {
				color: #333;
				cursor: pointer;
			}
		}
		span {
			color: rgba(51, 51, 51, 0.5);
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
		}
	}

	.message-card-box .card {
		height: 600px;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			color: #333;
			text-align: center;
			font-size: 14px;
		}
	}
}

.header-logo {
	background-color: #f8f8f8;
	border-bottom: 1px solid #e0e0e0;
	padding: 20px 15px;
	.inner-header-logo {
		justify-content: space-between;
		display: flex;
	}
	.admin-profile {
		font-size: 16px;
		line-height: 100%;
		color: #828282;
		font-weight: 500;
	}
}

.filter-overlay-inner {
	.form-group {
		margin-bottom: 0.5rem;
	}
    label {
		font-size: 12px;
		margin-bottom: 0;
	}
}
