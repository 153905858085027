.activities-questions {
  background: #f8f8f8;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px 23px;
  margin-bottom: 10px;

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #6e6b7b;
  }

  .upload-box {
    @media screen and (max-width: 767px) {
      margin-right: 10px;
    }

    &.task-data-upload {
      .activities-btn {
        margin-left: 0px;

        @media screen and (max-width: 991px) {
          margin: 10px;
        }
      }
    }
  }

  .select-input,
  textarea,
  input {
    max-width: 100%;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #283046;
  }

  .response-question {
    .form-group {
      width: 100%;
    }

    .response-field {
      position: relative;
      width: 100%;

      img {
        position: absolute;
        right: 3px;
        bottom: 17px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 1px 7px #ababab;
        }
      }
    }
  }
}

.checklist-view {
  .task-detail-view {
    position: relative;
    margin-bottom: 43px;
    position: relative;

    @media screen and (max-width: 767px) {
      padding: 10px;
    }

    .ddl-select {
      width: 100%;
    }

    .select-input {
      .select__control--menu-is-open {
        transition: 0.4s ease-in-out;
      }

      min-width: 205px;

      @media screen and (max-width: 1300px) {
        min-width: 210px;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }
  }
}

.additional-response {
  .select-response,
  input,
  textarea {
    width: 100%;
    max-width: 100%;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #6e6b7b;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #6e6b7b80;
    border-radius: 2px;
  }

  .additional-response-title {
    h6 {
      text-transform: capitalize;
      padding-right: 10px;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }

    svg {
      cursor: pointer;
    }
  }

  .response-question-list {
    margin-bottom: 17px;

    .form-group {
      margin: 0;
    }

    .response-question {
      .form-group {
        width: 100%;
      }

      .response-field {
        position: relative;
        width: 100%;

        img {
          position: absolute;
          right: 30px;
          bottom: 3px;
          border-radius: 50%;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 1px 7px #ababab;
          }
        }
      }

      textarea,
      input {
        margin-right: 25px;
      }
    }
  }
}

.response-question {
  .select-response,
  input,
  textarea {
    &::-webkit-input-placeholder {
      /* Edge */
      text-transform: capitalize;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer */
      text-transform: capitalize;
    }

    .select__placeholder,
    &::placeholder {
      text-transform: capitalize;
    }
  }

  .select-input {
    .select__placeholder {
      text-transform: capitalize;
    }
  }

  .form-group {
    width: 100%;
  }

  .response-input {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .custom-control {
      .custom-control-label {
        cursor: pointer;
      }

      .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: rgba(#1b68b6, 0.65) !important;
      }

      .custom-control-input:not(:checked) ~ .custom-control-label::before {
        border-color: rgba(#1b68b6, 0.65) !important;
        border-width: 2px;
      }
    }
  }

  .foresite_standard_options {
    .common-button {
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0px !important;

        @media screen and (max-width: 767px) {
          margin-right: 15px !important;
        }
      }
    }

    .btn-unapproved {
      background-color: #ea5455 !important;
      border-color: #ea5455 !important;

      &:active,
      &:focus {
        background-color: #ea5455 !important;
      }
    }

    .btn-concealed {
      background-color: #ff9f43 !important;

      &:active,
      &:focus {
        background-color: #ff9f43 !important;
      }
    }

    .btn-na {
      background-color: #e3e6ea !important;
      color: #172b4d !important;

      &:active,
      &:focus {
        background-color: #e3e6ea !important;
      }
    }

    .btn-approved {
      background-color: #28c76f !important;

      &:active,
      &:focus {
        background-color: #28c76f !important;
      }
    }
  }

  .button_response_options {
    .common-button {
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0px !important;

        @media screen and (max-width: 767px) {
          margin-right: 15px !important;
        }
      }
    }
  }
}

.accident-place-img {
  display: flex;
  margin-left: 15px;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
  }

  .photo-acc-img {
    position: relative;
    width: 82px;
    height: 70px;
    margin-left: 6px;
  }
}

.upload-box {
  z-index: 99;
  input.uploadInput {
    display: none;
  }

  @media screen and (max-width: 767px) {
    margin-right: 10px;
  }

  svg {
    margin-right: 5px;
  }
}

.response-question .response-textarea {
  line-height: 14px;

  &.is-primary {
    @media screen and (max-width: 767px) {
      margin: 0px;
      margin-bottom: 10px;
    }
  }

  &.is-additional {
    margin-top: 10px;
  }

  min-width: 40%;

  @media screen and (max-width: 767px) {
    margin: 0px;
    margin-bottom: 10px;
  }
}

.range-inputbox {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    margin-right: 10px;
  }

  input:last-child {
    margin-right: 0px !important;
  }

  .range-field {
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.response-disabled {
  .custom-control-label {
    cursor: default !important;
  }
}

.additional-note {
  max-width: 490px;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  min-height: 146px;
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 16px;
  margin-top: 1rem;

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 155%;
    color: #828282;
  }

  .additional-note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 0;
      margin: 0px 5px;
    }

    .additional-note-title,
    button {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #4f4f4f;
    }
  }
}

.additional-note-imgs {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: end;
  max-width: 490px;

  .add-note-photo {
    width: 44px;
    height: 44px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }

    .close-img {
      position: absolute;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 13px;
      bottom: 0;
      color: white;
      width: 14px;
      height: 14px;
      z-index: 99;
      margin: 0 auto;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:after {
      position: absolute;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
      width: 100%;
      height: 100%;
      right: 0;
      left: 0;
    }
  }
}

.activity-response-overlay {
  position: absolute;
  background: transparent;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

.form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;

  .disabled {
    background-color: #efefef;
  }
}
