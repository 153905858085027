body {
	background: #f8f8f8;
	font-family: 'Montserrat', sans-serif;
	@media (max-width: 767px) {
		background-color: rgb(255, 255, 255);
	}
}
.outer {
	background: #f8f8f8;
	height: 100%;
	@media (max-width: 767px) {
		height: 100%;
		background-color: rgb(255, 255, 255);
	}
}
.content-header {
	margin-top: 28px;
	margin-bottom: 12px;
	svg {
		width: 14px;
		height: 14px;
		color: #828282;
		margin: 0 4px;
	}
	h1 {
		a {
			color: #4f4f4f;
		}
		color: #4f4f4f;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
	}
}

// Custom Radio
.custom-radio {
  .custom-control-label::before {
    top: 0.1rem;
  }
}

// Custom checkbox and radio in common
.custom-checkbox,
.custom-radio {
  &.custom-control {
    padding-left: 1.8rem;

    .input-group & {
      padding-left: 1.3rem;
    }
  }

  .custom-control-input {
    width: 1.285rem;
    height: 1.285rem;
  }

  .custom-control-label {
    font-size: $font-size-base;
    position: static;
    // vertical-align: middle;
    &::before,
    &::after {
      width: 18px;
      height: 18px;
      left: 0;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    box-shadow: 0 2px 4px 0 rgba($custom-control-indicator-checked-bg, 0.4) !important;
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    border: none;
    box-shadow: none !important;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: none;
    box-shadow: 0 2px 4px 0 rgba($custom-control-indicator-checked-bg, 0.4) !important;
  }
}
.grey-solid{
  &:disabled{
    background: #E0E0E0;
    border-color: #E0E0E0;
    opacity: 1;
  }
}