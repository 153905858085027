.upload-modal {
	max-width: 640px;
	.modal-content {
		padding: 43px 20px;
	}
	.rc-uploader-tabs {
		margin-bottom: 20px;
		text-align: center;
		span.active {
			border-bottom: 2px solid #1b68b6;
			padding-bottom: 8px;
		}
	}
	.file-details-inner {
		margin-top: 16px !important;
	}
	.delete-btn {
		border: 1px solid #ea5455 !important;
		background-color: #fff !important;
		padding: 9px 10px !important;
		border: 1px solid #ea5455 !important;
		color: #ea5455 !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.4px;
	}
	.btn.disabled,
	.btn:disabled {
		opacity: 0.65;
	}
	.bg-btn {
		background: #1b68b6 !important;
		line-height: 17px;
		border: 1px solid #1b68b6 !important;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.4px;
		min-width: 97px;
		padding: 9px 10px !important;
		color: rgb(255, 255, 255) !important;
		.bg-btn:focus,
		.bg-btn:hover {
			background: #1b68b6 !important;
			border: 1px solid #1b68b6 !important;
		}
	}
	.accepted-files {
		max-height: 400px;
		overflow-y: auto;
	}
	.file-details {
		.resume-box {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}
	.file-details {
		.form-group {
			background-color: #f9f9f9;
			padding: 10px;
		}
		input {
			width: 80%;
			margin-left: 60px;
		}
		label {
			font-weight: 600;
			font-size: 12px;
			line-height: 18px;
			margin: 0;
		}
		svg {
			color: red;
			top: 10px;
			cursor: pointer;
			margin-left: 10px;
			right: 10px;
		}
		.doc-ext {
			background-color: #ebebeb;
			padding: 10px;
			margin-top: 0;
			border-radius: 5px;
			height: 100%;
		}
	}
	.mr-4 {
		margin-right: 3.5rem !important;
	}
	.form-control {
		color: #6e6b7b;
	}
}
