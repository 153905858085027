.mobile-view-detail-outer {
	.card-action-btn {
		.add-note-buttons {
			display: flex;
		}
	}
	.additional-note {
		width: 100%;
	}
	.btn-secondary:not(:disabled):not(.disabled):active:focus,
	.btn-secondary:not(:disabled):not(.disabled).active:focus {
		box-shadow: none;
		color: #000;
		border: 1px solid #e0e0e0;
	}
	.section-tabs-border {
		.mobile-tabs-scroll {
			overflow: scroll;
			border-bottom: 1px solid #e0e0e0;
		}
		.container {
			padding: 0;
		}
		.section-tabs {
			flex-wrap: nowrap;
			width: 500px;
			margin: 0px 20px;
			button {
				margin: 0px 7px;
				border-radius: 4px 4px 0px 0px;
				border: 1px solid #e0e0e0;
				background: #f8f8f8;
				color: #828282;
				font-size: 14px;
				width: auto;
				border-bottom: none;
				margin-left: 0;
				&.active {
					background: #ffffff;
					color: #fff;
					color: #333;
				}
			}
		}
		.task-detail-view {
			padding: 20px;
			.btn.disabled,
			.btn:disabled {
				opacity: 1;
			}
			.activities-questions {
				background-color: transparent;
				border: none;
				padding: 0;
			}
			.activities-questions {
				p {
					color: #333;
					font-size: 15px;
					font-weight: 400;
					line-height: 130%;
					margin-bottom: 10px;
				}
			}
			.response-question {
				.foresite_standard_options {
					width: 100%;
				}
				.btn-unapproved {
					border: 1px solid #e0e0e0;
					background: #eb5757;
					width: 100%;
					color: #fff;
					font-size: 12px;
					font-weight: 600;
					border-radius: 2px;
					line-height: 100%;
				}
			}
		}
		.timeline {
			padding: 20px;
			.audit-trail-header {
				flex-wrap: wrap;
				h3 {
					color: #333;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 100%;
				}
			}
			.audit-trail-details {
				padding-top: 20px;
				width: 100%;
			}
		}
		.activities-questions {
			background-color: transparent;
		}
		.activity-detail-outer {
			padding: 20px;
			.activity-detail-item {
				color: #333;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				margin-bottom: 15px;
			}
		}
	}
	.action-task-details,
	.action-detail-list {
		border-bottom: none;
	}
	.correction-details {
		padding: 20px;
		.audit-trail-detail {
			margin-top: 15px;
		}
		.audit-trail-header {
			flex-wrap: wrap;
			.audit-trail-details {
				margin-top: 10px;
				width: 100%;
			}
		}
	}
}
.mobile-view-action-outer {
	.inner-header-logo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.filter-view {
			cursor: pointer;
			border-right: 1px solid #b2b2b2;
			padding: 0px 10px;
			margin: 0px 10px;
		}
	}
	.filter-overlay {
		position: fixed;
		background: #ffffff;
		width: 100%;
		z-index: 99;
		top: 0;
		left: 0;
		right: 0;
		padding: 20px;
		margin: 0 auto;
		box-shadow: 4px 4px 20px #dcdcdc;
		background-color: #00000080;
		height: 100%;
		.filter-overlay-inner {
			background: white;
			padding: 20px;
		}
	}
}
